<template>
  <BaseMultiselect
    mode="tags"
    :close-on-select="true"
    :searchable="true"
    :create-option="true"
  >
    <template v-slot:tag="{ option, handleTagRemove, disabled }">
      <span
        class="bg-gray-100 text-gray-700 text-sm font-semibold py-0.5 px-1 rounded mr-1 mb-1 flex items-center whitespace-nowrap"
      >
        <div><BaseIcon name="heroicons:hashtag-20-solid" color="gray" /></div>
        <div class="px-1">{{ option.value }}</div>
        <span
          v-if="!disabled"
          class="multiselect-tag-remove"
          @click="handleTagRemove(option, $event)"
        >
          <BaseIcon name="heroicons:x-mark" />
        </span>
      </span>
    </template>
  </BaseMultiselect>
</template>

<script setup lang="ts"></script>
